@import '../../styles/_variables';
@import '../../styles/_animations';
@import '../../styles/_mixins';

#about {
  padding-top: 7rem;
  @include mediumBreakpoint {
    margin: 0;
  }
  @include largeBreakpoint {
    padding-bottom: 1rem;
  }
  @include extraLargeBreakpoint {
    margin: 0;
  }
  .wrapper > .subtitle {
    text-align: left;
    @include extraSmallThruSmallBreakpoints {
      font-size: 1.3rem;
      margin-bottom: 2rem;
    }
    @include mediumBreakpoint {
      font-size: 1.3rem;
      margin-bottom: 2rem;
    }
  }
  .wrapper > .long-description {
    line-height: 1.5;
    margin-top: 2rem;
    max-width: 55rem;
    @include extraSmallThruMediumBreakpoints {
      width: 100%;
      line-height: 1.7;
      margin-top: 1rem;
      font-size: 1.2rem;
    }
  }
  p a {
    text-decoration: underline;
  }
  p a:hover {
    text-decoration-thickness: .25em;
  }
}