$font-stack: 'Noto Sans', Helvetica, Arial sans-serif;
$primary-color: #000000;
$color-white: #ffffff;
$color-orange: #ee7752;
$color-pink: #e73c7e;
$color-blue: #23a6d5;
$color-seafoam: #23d5ab;

/************ Screen sizes - width **************/
$screen-xsm: 300px;
$screen-sm-start: 301px;
$screen-sm-end: 500px;
$screen-md-start: 501px;
$screen-md-end: 768px;
$screen-l-start: 769px;
$screen-l-end: 1024px;
$screen-xl-start: 1025px;
$screen-xl-end: 1200px;
// $screen-full: 1400px;

/************ Screen sizes - height **************/
$screenHeight-sm: 300px;
$screenHeight-md: 600px;

/****** Extra small mobile devices (Galaxy Fold, etc.) ******/
// @media screen and (max-width: 300px)

/****** Mobile devices ******/
// @media screen and (min-width: 301px) and (max-width: 500px)

/****** iPads, Tablets ******/
// @media screen and (min-width: 501px) and (max-width: 768px)

/****** Small screens, laptops ******/
// @media screen and (min-width: 769px) and (max-width: 1024px)

/****** Desktops, large screens ******/
// @media screen and (min-width: 1025px) and (max-width: 1200px)