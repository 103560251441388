@import '../../styles/_variables';
@import '../../styles/_animations';
@import '../../styles/_mixins';


.project-tile {
  display: flex;
  flex-direction: column; 
  padding-bottom: 50px;
  @include extraSmallBreakpoint {
    padding-bottom: 100px;
  }
  @include smallBreakpoint {
    padding-bottom: 100px;
  }
  .project-image-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include extraSmallThruMediumBreakpoints {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .container-img {
      width: 50%;
      @include extraSmallThruSmallBreakpoints {
        width: 100%;
      }
      @include mediumBreakpoint {
        width: 100%;
        margin-bottom: 30px;
      }
    }
    .project-image {
      width: 100%;
      height: auto;
      display: block;
      border: 2px solid #bcbcbc;
      @include extraSmallBreakpoint {
        width: 100%;
        margin-bottom: 30px;
      }
      @include smallBreakpoint {
        width: 100%;
        margin-bottom: 30px;
      }
    }
    .project-title {
      width: 50%;
      display: flex;
      flex-direction: column;
      @include extraSmallThruMediumBreakpoints {
        width: 100%;
      }
      .project-link {
        margin-right: 30px;
        width: fit-content;
      }
      .project-repo {
        width: fit-content;
      }
      .project-link > span, .project-repo > span {
        border: 1px solid;
        padding: 20px;
        transition: .3s;
      }
      .project-link > span:hover, .project-repo > span:hover {
        background-color: $primary-color;
        color: $color-white;
        border: 1px solid $primary-color;
        padding: 20px;
      }
      .attribution {
        font-size: .9rem;
        padding-top: 1rem;
      }
      div > p {
        line-height: 1.7;
        @include extraSmallThruMediumBreakpoints {
          line-height: 1.7;
          font-size: 1.2rem;
        }
      }
      div:nth-of-type(1) > h3 {
        font-size: 1.5rem;
        padding-bottom: 10px;
        @include extraSmallThruSmallBreakpoints {
          font-size: 1.2rem;
        }
        @include mediumBreakpoint {
          font-size: 1.3rem;
        }
      }
      div:nth-of-type(2) {
        padding-top: 40px;
        display: flex;
        flex-direction: row;
        @include extraSmallBreakpoint {
          padding-top: 40px;
          flex-direction: column;
        }
        @include largeBreakpoint {
          padding-top: 40px;
        }
        a:nth-of-type(2) {
          @include extraSmallBreakpoint {
            padding-top: 60px;
          }
        }
      }
    }
  }
}
.project-tile:not(:first-of-type) {
  padding-top: 7%;
}
.project-tile:nth-child(odd) > .project-image-text > .project-title {
  padding-left: 5%;
  @include extraSmallThruMediumBreakpoints {
    padding-left: 0;
  }
}
.project-tile:nth-child(even) > .project-image-text {
  padding-right: 5%;
  display: flex;
  flex-direction: row-reverse;
  @include extraSmallThruSmallBreakpoints {
    padding: 0;
    flex-direction: column;
  }
  @include mediumBreakpoint {
    flex-direction: column;
  }
}
.project-tile:nth-child(even) > .project-image-text > .project-title {
  padding-right: 5%;
}




