@import '../../styles/_variables';
@import '../../styles/_mixins';
@import '../../styles/_animations'; 

#welcome-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  height: 70vh;
  border: none;
  background-color: $primary-color;
  color: $color-white;
  @include extraSmallBreakpoint {
    padding-top: 5rem;
    margin: 0;
  }
  @include smallBreakpoint {
    padding-top: 5rem;
    margin: 0;
  }
  @include mediumBreakpoint {
    margin: 0;
  }
  // @include mediumToLargeWidthMedHeight {

  // }
  @include extraLargeBreakpoint {
    padding: 10% 0;
  }
  h1 {
    font-size: 5rem;
    padding-bottom: 1rem;
    padding-top: 10%;
    @include extraSmallBreakpoint {
      font-size: 1.8rem;
      padding: 0 1rem .75rem 0;
    }
    @include smallBreakpoint {
      font-size: 2rem;
      padding: 0 1rem .75rem 0;
    }
    @include mediumBreakpoint {
      font-size: 3rem;
      padding: 0 1rem .75rem 0;
    }
    @include largeBreakpoint {
      font-size: 4rem;
    }
    @include extraLargeBreakpoint {
      font-size: 4.5rem;
    }
    @include smallMedHeight {
      font-size: 4rem;
    }
    span {
      display: inline-block;
      font-family: 'Noto Emoji';
      font-weight: 400;
      background-color: $color-white;
      background-image: linear-gradient(-45deg, $color-orange, $color-pink, $color-blue, $color-seafoam);
      background-size: 200% 200%;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent; 
      -moz-text-fill-color: transparent;
      animation: gradient 10s ease infinite;
    }
  }
  .welcome-subtitle {
    font-size: 2rem;
    border: none;
    width: 70%;
    @include extraSmallBreakpoint {
      font-size: 1rem;
      width: 45%;
    }
    @include smallBreakpoint {
      font-size: 1.2rem;
      width: 65%;
    }
    @include mediumBreakpoint {
      font-size: 1.4rem;
      width: 65%;
    }
    @include smallMedHeight {
      font-size: 1.3rem;
    }
    // .container-rotate span {
    //   opacity: 0;
    //   animation: rotate-word 10s linear infinite;
    //   position: absolute;
    //   overflow: hidden;
    // }
    // .container-rotate span:nth-child(2) {
    //   animation-delay: 2.5s;
    // }
    // .container-rotate span:nth-child(3) {
    //   animation-delay: 5s;
    // }
    // .container-rotate span:nth-child(4) {
    //   animation-delay: 7.5s;
    // }
    // .rotate {
    //   text-indent: .25em;
    // }
  }
  .download-button {
    padding-top: 3.5rem;
    z-index: 1;
    @include smallMedHeight {
      padding-top: 2rem;
    }
    .download-link > span {
      font-size: 1.4rem;
      color: $color-white;
      border: 1px solid $color-white;
      padding: 15px;
      transition: .3s;
      @include extraSmallBreakpoint {
        font-size: 1rem;
        padding: 10px;
      }
      @include smallBreakpoint {
        font-size: 1rem;
        padding: 10px;
      }
      @include smallMedHeight {
        font-size: 1.2rem;
        padding: 15px;
      }
    }
    .download-link > span:hover {
      background-color: $color-white;
      color: $primary-color;
      border: 1px solid $color-white;
      padding: 15px;
    }
  }
}


