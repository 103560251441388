@import '../../styles/_variables';
@import '../../styles/_animations';
@import '../../styles/_mixins';

#contact {
  color: #ffffff;
  background-color: #000000;
  padding: 2rem 0 6rem 0;
  text-align: center;
  @include extraSmallThruSmallBreakpoints {
    padding: 1rem 5% 0 5%;
  }
  @include mediumBreakpoint {
    padding: 1rem 0 1rem 0;
  }
  .subtitle {
    @include extraSmallThruMediumBreakpoints {
      font-size: 1.7rem;
    }
  }
  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3.5rem;
    font-size: 1.3rem;
    @include extraSmallThruSmallBreakpoints {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2rem 0 0 0;
      font-size: 1.3rem;
    }
    @include mediumBreakpoint {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.3rem;
    }
    li {
      @include extraSmallThruSmallBreakpoints {
        padding: 20px 0 20px 0;
      }
      @include mediumBreakpoint {
        padding: 20px 0 20px 0;
        width: 125px;
        display: flex;
        flex-direction: row;
      }
    }
    li > a {
      color: $color-white;
    }
    .gradient-link::after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 4px;
      right: 0;
      position: absolute;
      background: linear-gradient(to left, $color-orange, $color-pink, $color-blue, $color-seafoam 100%);
      background-size: 200% 200%;
      transition: width .5s ease 0s, right .5s ease 0s;
      animation: gradient 10s ease infinite;
      width: 0;
    }
    .gradient-link {
      color: $color-white;
      position: relative;
      padding-bottom: 7px;
    } 
    .gradient-link:hover:after {
      left: 0;
      width: 100%;
    } 
    .footer-fontAwesome {
      margin-right: .75rem;
    }
  }
  ul :not(:last-child) {
    margin-right: 4rem;
    @include extraSmallThruMediumBreakpoints {
      margin-right: 0;
    }
  }
  .footer-line {
    padding-bottom: 4rem;
    margin: 3rem 20% 0 20%;
    @include extraSmallThruSmallBreakpoints {
      border: none;
      padding-bottom: 2rem;
      margin: 3rem 0 0 0;
    }
    @include mediumBreakpoint {
      border: none;
      padding-bottom: 4rem;
    }
    @include largeBreakpoint {
      padding-bottom: 4rem;
      margin: 3rem 5% 0 5%;
    }
    @include extraLargeBreakpoint {
      margin: 3rem 15% 0 15%;
    }
  }
}




