@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes rotate-word {
  0% {
    opacity: 0;
  }
  1% {
    transform: translateY(-20px);
  }
  4% {
    opacity: 0;
  }
  5% {
    opacity: 1;
    transform: translateY(0px);
  }
  17% {
    opacity: 1;
    transform: translateY(0px);
  }
  19% {
    opacity: 0;
  }
  20% {
    transform: translateY(20px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}